import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {Button} from '@mui/material';
import {AsyncState, GroupView, useApplicationView, usePersonaId} from '@ozark/common';
import {useCallable} from '@ozark/common/hooks/useCallable';
import Persona, {Client} from 'persona';
import {Field, InquiryAttributes} from 'persona/dist/lib/interfaces';
import {useEffect} from 'react';

type Props = {
  applicationId: string;
  group: AsyncState<GroupView>;
  token: string;
  onSuccess: (license: string) => void;
};

let personaClient: Client;
export const PersonaIdVerificationLink = ({onSuccess, group, token, applicationId}: Props) => {
  const {application} = useApplicationView(applicationId);
  const {savePersonaIdAsync} = usePersonaId();
  const {
    encryptField,
    getPersonaIdConfig,
    documentsUploadPersonaSaveData,
    getDocumentsUploadPersonaGetData,
  } = useCallable();

  const initPersona = async (refId: string) => {
    if (personaClient) return;

    getPersonaIdConfig().then(config => {
      personaClient = new Persona.Client({
        templateId: config.data.template,
        environmentId: config.data.environment,
        referenceId: refId,
        onReady: () => {},
        onComplete: async ({
          inquiryId,
          status,
          fields,
        }: {
          inquiryId: string;
          status: string;
          fields: Record<string, Field> | InquiryAttributes;
        }) => {
          const selectedFields = fields as any;
          if (status === 'completed' && selectedFields['identification-class'].value === 'dl') {
            const newValue = {
              isVerified: true,
              driverLicenseNumber: selectedFields['identification-number'].value,
            } as any;
            await documentsUploadPersonaSaveData({token: token, data: newValue});
            onSuccess(selectedFields['identification-number'].value);
          }

          personaClient.cancel(false);
        },
      });
    });
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await getDocumentsUploadPersonaGetData({
          token,
        });
        if (response.status === 'ok') {
          if (!response.data?.referenceId) {
            const encryptEmail = async () => await encryptField(response.email);

            encryptEmail().then(encryptedResult => {
              if (encryptedResult) {
                const personaId = {
                  isVerified: false,
                  referenceId: encryptedResult.encryptedText,
                  rerefenceIdSalt: encryptedResult.salt,
                };
                savePersonaIdAsync(applicationId, personaId);
                initPersona(encryptedResult.encryptedText);
              }
            });
          } else {
            initPersona(response.data.referenceId);
          }
        } else {
          console.error(response);
        }
      } catch (err) {
        console.error(err);
      }
    })();
  }, [getDocumentsUploadPersonaGetData, token, initPersona, applicationId]);

  return (
    <Button
      variant="contained"
      size={'small'}
      color={'secondary'}
      style={{fontSize: '1em'}}
      onClick={() => {
        personaClient.open();
      }}
      endIcon={<ArrowForwardIosIcon />}
    >
      Verify your account with Persona ID
    </Button>
  );
};
